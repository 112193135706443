import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, Chip, Link, CardHeader, Image, Divider } from '@nextui-org/react';
import axios from 'axios';
import { Form, Input, Button, Select, Checkbox, Tabs } from "antd";
import { ArrowsRightLeftIcon, ArrowTrendingUpIcon, ClockIcon, BanknotesIcon, CreditCardIcon, EnvelopeOpenIcon, CursorArrowRaysIcon, LifebuoyIcon, ArrowPathIcon, StarIcon, UserGroupIcon, InformationCircleIcon, XMarkIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { router, Head } from '@inertiajs/react';
import { FaAnglesDown, FaRegWindowRestore } from "react-icons/fa6";
import { FiCornerRightDown, FiCornerRightUp } from "react-icons/fi";
import { FcAcceptDatabase, FcAreaChart, FcDiploma2, FcExpired, FcFeedback, FcLink, FcLock, FcMoneyTransfer, FcPositiveDynamic, FcVip } from 'react-icons/fc';
import { PiSpeedometerDuotone } from 'react-icons/pi';
import { SiTrustpilot } from 'react-icons/si';
import { HiOutlineExternalLink } from 'react-icons/hi';
import TabPane from 'antd/es/tabs/TabPane';

interface Currency {
    id: number;
    symbol: string;
    name: string;
    image_url: string;
    min_exchange_amount: string;
    max_exchange_amount: string;
    reserve: number;
}

interface FakeExchangeRequest {
    created_at: string;
    from_currency: Currency;
    to_currency: Currency;
    amount_give: number;
    amount_receive: number;
    wallet_address: string;
    status: string;
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
    fakeExchanges?: FakeExchangeRequest[]; // Новый пропс
    reviews: Review[]; // Добавлено поле отзывов
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
}

interface Review {
    id: number;
    name: string;
    email: string;
    rating: number;
    description: string;
    image_feedback?: string;
    id_exchange: number;
    verified: boolean;
    image_feedback_url?: string; // Если используется кастомный атрибут для URL изображения
}

const { Option } = Select;

const Welcome: React.FC<WelcomeProps> = ({ giveCurrencies, getCurrencies, rate, fakeExchanges, error, reviews }) => {
    const [selectedGiveCurrency, setSelectedGiveCurrency] = useState<string>(giveCurrencies[0]?.id.toString() || '');
    const [selectedGetCurrency, setSelectedGetCurrency] = useState<string>(() => {
        // Устанавливаем первую доступную валюту, которая не совпадает с "отдаю"
        const defaultGetCurrency = getCurrencies.find(currency => currency.id.toString() !== giveCurrencies[0]?.id.toString());
        return defaultGetCurrency ? defaultGetCurrency.id.toString() : '';
    });
    const buttonGiveRef = useRef<HTMLButtonElement | null>(null);
    const buttonGetRef = useRef<HTMLButtonElement | null>(null);

    // Позиции выпадающих списков
    const [giveDropdownPosition, setGiveDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
    const [getDropdownPosition, setGetDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

    // Видимость выпадающих списков
    const [isGiveDropdownOpen, setIsGiveDropdownOpen] = useState(false);
    const [isGetDropdownOpen, setIsGetDropdownOpen] = useState(false);

    const calculateDropdownPosition = (
        buttonRef: React.RefObject<HTMLButtonElement>,
        setDropdownPosition: React.Dispatch<React.SetStateAction<{ top: number; left: number; width: number; }>>
    ) => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    const toggleGiveDropdown = () => {
        setIsGiveDropdownOpen(!isGiveDropdownOpen);
        if (!isGiveDropdownOpen) {
            calculateDropdownPosition(buttonGiveRef, setGiveDropdownPosition);
        }
    };

    const toggleGetDropdown = () => {
        setIsGetDropdownOpen(!isGetDropdownOpen);
        if (!isGetDropdownOpen) {
            calculateDropdownPosition(buttonGetRef, setGetDropdownPosition);
        }
    };

    const scrollToExchange = () => {
        if (window.location.pathname === "/") {
            document.getElementById("exchange")?.scrollIntoView({ behavior: "smooth" });
        } else {
            router.visit("/#exchange");
        }
    };

    useEffect(() => {
        if (isGiveDropdownOpen || isGetDropdownOpen) {
            document.body.style.overflow = 'hidden'; // Блокировка прокрутки страницы
        } else {
            document.body.style.overflow = 'auto'; // Разблокировка прокрутки
        }

        // Очистка эффекта при размонтировании компонента
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isGiveDropdownOpen, isGetDropdownOpen]);


    const selectedGiveCurrencyObj = giveCurrencies.find((currency) => currency.id.toString() === selectedGiveCurrency);
    const selectedGetCurrencyObj = getCurrencies.find((currency) => currency.id.toString() === selectedGetCurrency);

    const [amount, setAmount] = useState<string>('1117');
    const [receivedAmount, setReceivedAmount] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number | null>(rate || null);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [timer, setTimer] = useState<number>(15);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

    // Состояния для чекбоксов
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isAMLAccepted, setIsAMLAccepted] = useState<boolean>(false);

    // Состояния для ошибок
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false); // Состояние для отслеживания валидности формы
    const [isLoading, setIsLoading] = useState<boolean>(false); // Новое состояние для загрузки

    // Функция для получения курса с помощью axios
    const fetchExchangeRate = async (giveCurrencyId: string, getCurrencyId: string) => {
        try {
            const response = await axios.post('/get-exchange-rate', {
                give_currency_id: giveCurrencyId,
                get_currency_id: getCurrencyId,
            });

            if (response.data.rate) {
                setExchangeRate(response.data.rate);
            }
        } catch (error) {
            console.error('Ошибка при получении курса', error);
        }
    };

    // Рассчитываем получаемую сумму при изменении курса или суммы
    useEffect(() => {
        if (exchangeRate && amount) {
            const result = (parseFloat(amount) * exchangeRate).toFixed(2);
            setReceivedAmount(result);
        } else {
            setReceivedAmount('');
        }
    }, [amount, exchangeRate]);

    // Обновляем курс при изменении валют
    useEffect(() => {
        fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Таймер для обновления курса каждые 15 секунд
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer((prev) => {
                if (prev === 1) {
                    fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
                    return 15;
                } else {
                    return prev - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Функция для обмена валют
    const swapCurrencies = () => {
        const previousGiveCurrency = selectedGiveCurrency;
        setSelectedGiveCurrency(selectedGetCurrency);
        setSelectedGetCurrency(previousGiveCurrency);
    };

    const handleGiveCurrencyChange = (value: { value: string, label: React.ReactNode }) => {
        setSelectedGiveCurrency(value.value); // Сохраняем выбранную валюту
    };

    const handleGetCurrencyChange = (value: { value: string, label: React.ReactNode }) => {
        setSelectedGetCurrency(value.value); // Сохраняем выбранную валюту
    };

    // Валидация полей
    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        // Проверка суммы
        if (!amount || isNaN(Number(amount)) || parseFloat(amount) <= 0) {
            newErrors.amount = 'Введите корректную сумму';
        } else {
            const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
            const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

            if (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount) {
                newErrors.amount = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
            }
        }

        // Проверка кошелька
        if (!walletAddress) {
            newErrors.walletAddress = 'Введите адрес кошелька';
        }

        // Проверка email
        if (!email) {
            newErrors.email = 'Введите email';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'Введите корректный email';
        }

        // Проверка чекбоксов
        if (!isTermsAccepted || !isAMLAccepted) {
            newErrors.terms = 'Пожалуйста, примите все условия';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Валидация отдельных полей в реальном времени
    const validateAmount = (value: string) => {
        let error = '';
        const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
        const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

        if (!value || isNaN(Number(value)) || parseFloat(value) <= 0) {
            error = 'Введите корректную сумму';
        } else if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
            error = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
        }
        setErrors(prev => ({ ...prev, amount: error }));
        checkFormValidity();
    };

    const validateWalletAddress = (value: string) => {
        let error = value ? '' : 'Введите адрес кошелька';
        setErrors(prev => ({ ...prev, walletAddress: error }));
        checkFormValidity();
    };

    const validateEmail = (value: string) => {
        let error = '';

        if (!value) {
            error = 'Введите email';
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
            error = 'Введите корректный email';
        }

        setErrors(prev => ({ ...prev, email: error }));
        checkFormValidity();
    };

    // Проверка валидности формы
    const checkFormValidity = () => {
        const isValid = !errors.amount && !errors.walletAddress && !errors.email &&
            !!amount && !!walletAddress && !!email &&
            isTermsAccepted && isAMLAccepted;

        console.log('Checking form validity:', {
            amountError: errors.amount,
            walletAddressError: errors.walletAddress,
            emailError: errors.email,
            amount: !!amount,
            walletAddress: !!walletAddress,
            email: !!email,
            isTermsAccepted,
            isAMLAccepted,
            isValid
        });

        setIsFormValid(isValid);
    };

    // Обработка отправки данных
    const handleSubmit = async () => {
        if (!validateFields()) {
            setErrors({ ...errors, submit: 'Необходимо исправить ошибки' });
            return;
        }

        setIsLoading(true);
        try {
            const response = await axios.post('/exchange/create', {
                give_currency_id: selectedGiveCurrency,
                get_currency_id: selectedGetCurrency,
                amount_give: amount,
                amount_receive: receivedAmount,
                wallet_address: walletAddress,
                email: email,
            });

            if (response.data.success) {
                router.visit(`/exchange/${response.data.request_id}`);
            }
        } catch (error) {
            console.error('Ошибка при создании заявки', error);
            setErrors({ ...errors, submit: 'Ошибка при создании заявки' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkFormValidity();
    }, [isTermsAccepted, isAMLAccepted, amount, walletAddress, email, errors]);


    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    return (
        <>
            <Head>
                <title>Главная</title>
                <meta name="description" content="Enzer Exchange" />
            </Head>
            <section className="relative py-12 overflow-hidden blured sm:pb-16 lg:pb-20 xl:pb-24 shadow-2xl">
                <div className="px-4 mx-auto relativea sm:px-6 lg:px-8 max-w-7xl">
                    <div className="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 gap-x-16">
                        <div>
                            <h1 className="font-semibold text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center text-center lg:text-start"><FcAreaChart /> Выгодные курсы</h1>
                            <p className="mt-4 text-lg font-normal text-zinc-500 sm:mt-8 text-center lg:text-start">У нас вы можете выгодно обменять любую доступную валюту на рынке, по выгодным курсам. Нет нужной валюты в списке? Обратитесь к администратору!</p>

                            <form action="#" method="POST" className="relative mt-8 rounded-full sm:mt-12">
                                <div className="hidden lg:block relative">
                                    <div className="absolute rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500"></div>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-6">
                                            <FcMoneyTransfer className="size-5" />
                                        </div>
                                        <input type="email" disabled name="" id="" placeholder="Самые выгодные курсы у нас" className="block w-full py-4 pr-6 text-white placeholder-gray-500 bg-transparent lg:bg-zinc-100 border border-transparent rounded-full pl-14 sm:py-5 focus:border-transparent focus:ring-0" />
                                    </div>
                                </div>
                                <div className="sm:absolute flex sm:right-1.5 sm:inset-y-1.5 mt-4 sm:mt-0">
                                    <Button onClick={scrollToExchange} className="h-full inline-flex items-center justify-center w-full px-5 py-5 text-sm font-semibold tracking-widest text-black uppercase transition-all duration-200 bg-white rounded-full sm:w-auto sm:py-3 hover:opacity-90">Создать заявку</Button>
                                </div>
                            </form>

                            <div className="mt-8 sm:mt-12">
                                <p className="text-lg font-normal text-zinc-600 flex justify-center lg:justify-start items-center"><SiTrustpilot className='text-green-500 size-6 mx-2' /> Trustpilot отзывы</p>

                                <div className="flex justify-center lg:justify-start items-center mt-3">
                                    <div className="flex">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8586 4.71248C11.2178 3.60691 12.7819 3.60691 13.1412 4.71248L14.4246 8.66264C14.5853 9.15706 15.046 9.49182 15.5659 9.49182H19.7193C20.8818 9.49182 21.3651 10.9794 20.4247 11.6626L17.0645 14.104C16.6439 14.4095 16.4679 14.9512 16.6286 15.4456L17.912 19.3958C18.2713 20.5013 17.0059 21.4207 16.0654 20.7374L12.7052 18.2961C12.2846 17.9905 11.7151 17.9905 11.2945 18.2961L7.93434 20.7374C6.99388 21.4207 5.72851 20.5013 6.08773 19.3958L7.37121 15.4456C7.53186 14.9512 7.35587 14.4095 6.93529 14.104L3.57508 11.6626C2.63463 10.9794 3.11796 9.49182 4.28043 9.49182H8.43387C8.95374 9.49182 9.41448 9.15706 9.57513 8.66264L10.8586 4.71248Z"
                                                fill="url(#b)"
                                            />
                                            <defs>
                                                <linearGradient id="b" x1="3.07813" y1="3.8833" x2="23.0483" y2="6.90161" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0%" />
                                                    <stop offset="100%" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8586 4.71248C11.2178 3.60691 12.7819 3.60691 13.1412 4.71248L14.4246 8.66264C14.5853 9.15706 15.046 9.49182 15.5659 9.49182H19.7193C20.8818 9.49182 21.3651 10.9794 20.4247 11.6626L17.0645 14.104C16.6439 14.4095 16.4679 14.9512 16.6286 15.4456L17.912 19.3958C18.2713 20.5013 17.0059 21.4207 16.0654 20.7374L12.7052 18.2961C12.2846 17.9905 11.7151 17.9905 11.2945 18.2961L7.93434 20.7374C6.99388 21.4207 5.72851 20.5013 6.08773 19.3958L7.37121 15.4456C7.53186 14.9512 7.35587 14.4095 6.93529 14.104L3.57508 11.6626C2.63463 10.9794 3.11796 9.49182 4.28043 9.49182H8.43387C8.95374 9.49182 9.41448 9.15706 9.57513 8.66264L10.8586 4.71248Z"
                                                fill="url(#b)"
                                            />
                                            <defs>
                                                <linearGradient id="b" x1="3.07813" y1="3.8833" x2="23.0483" y2="6.90161" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0%" />
                                                    <stop offset="100%" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8586 4.71248C11.2178 3.60691 12.7819 3.60691 13.1412 4.71248L14.4246 8.66264C14.5853 9.15706 15.046 9.49182 15.5659 9.49182H19.7193C20.8818 9.49182 21.3651 10.9794 20.4247 11.6626L17.0645 14.104C16.6439 14.4095 16.4679 14.9512 16.6286 15.4456L17.912 19.3958C18.2713 20.5013 17.0059 21.4207 16.0654 20.7374L12.7052 18.2961C12.2846 17.9905 11.7151 17.9905 11.2945 18.2961L7.93434 20.7374C6.99388 21.4207 5.72851 20.5013 6.08773 19.3958L7.37121 15.4456C7.53186 14.9512 7.35587 14.4095 6.93529 14.104L3.57508 11.6626C2.63463 10.9794 3.11796 9.49182 4.28043 9.49182H8.43387C8.95374 9.49182 9.41448 9.15706 9.57513 8.66264L10.8586 4.71248Z"
                                                fill="url(#b)"
                                            />
                                            <defs>
                                                <linearGradient id="b" x1="3.07813" y1="3.8833" x2="23.0483" y2="6.90161" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0%" />
                                                    <stop offset="100%" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8586 4.71248C11.2178 3.60691 12.7819 3.60691 13.1412 4.71248L14.4246 8.66264C14.5853 9.15706 15.046 9.49182 15.5659 9.49182H19.7193C20.8818 9.49182 21.3651 10.9794 20.4247 11.6626L17.0645 14.104C16.6439 14.4095 16.4679 14.9512 16.6286 15.4456L17.912 19.3958C18.2713 20.5013 17.0059 21.4207 16.0654 20.7374L12.7052 18.2961C12.2846 17.9905 11.7151 17.9905 11.2945 18.2961L7.93434 20.7374C6.99388 21.4207 5.72851 20.5013 6.08773 19.3958L7.37121 15.4456C7.53186 14.9512 7.35587 14.4095 6.93529 14.104L3.57508 11.6626C2.63463 10.9794 3.11796 9.49182 4.28043 9.49182H8.43387C8.95374 9.49182 9.41448 9.15706 9.57513 8.66264L10.8586 4.71248Z"
                                                fill="url(#b)"
                                            />
                                            <defs>
                                                <linearGradient id="b" x1="3.07813" y1="3.8833" x2="23.0483" y2="6.90161" gradientUnits="userSpaceOnUse">
                                                    <stop offset="0%" />
                                                    <stop offset="100%" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                    <span className="ml-2 text-base font-normal text-black"> 4.1/5 </span>
                                    <span className="ml-1 text-base font-normal text-gray-500"> (200+ Отзывов) </span>
                                </div>
                            </div>
                        </div>

                        <div className="hidden lg:block relative">
                            <div className="absolute inset-0">
                            </div>
                            <img className="relative w-full max-w-md mx-auto" src="/assets/img/illu.png" alt="" />
                        </div>
                    </div>
                    <Divider id="exchange" className='my-8' />
                    <div className='flex flex-col col-span-1'>
                        <h1 className="font-semibold text-center lg:text-start text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center lg:justify-start items-center"><FcDiploma2 className='mx-2' /> Создание заявки</h1>
                        <p className="text-center lg:text-start mt-4 text-lg font-normal text-zinc-500 sm:mt-8 flex items-center">Начните с выгодной операции в Disco Exchange! Высокий уровень безопасности и очень большая скорость обмена (до 15 минут)!</p>

                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 w-full h-full mt-4'>
                        <div className="hidden lg:flex lg:col-span-1 items-center justify-center">
                            <Image className="w-full object-cover border-5 border-gray-200 rounded-3xl" src="/assets/img/man.png" />
                        </div>
                        <Card className="lg:col-span-3 w-full flex items-center justify-center blured rounded-3xl shadow-full border-5 border-gray-200">
                            <CardBody className='lg:p-4'>
                                <div className='flex flex-col lg:flex-row justify-between items-center'>
                                    <div className="flex flex-col space-y-2">
                                        {/* Лейбл */}
                                        <label htmlFor="giveCurrency" className="ml-1 flex font-semibold items-center text-md text-gray-800">
                                            Я хочу отдать <FiCornerRightUp className="ml-1 text-purple-500" />
                                        </label>

                                        {/* Input с интегрированным Select */}
                                        <div className="flex items-center border rounded-lg  shadow-md p-2 h-[48px] bg-white w-full">
                                            <Input
                                                size='large'
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                                placeholder="0.00"
                                                className="w-full border-none focus:ring-0 bg-white font-semibold"
                                            />

                                            <Select
                                                labelInValue
                                                variant='filled'
                                                size='large'
                                                value={{
                                                    value: selectedGiveCurrency, // Значение текущей выбранной валюты
                                                    label: (
                                                        <div className="flex items-center justify-between">
                                                            <img
                                                                src={giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.image_url}
                                                                alt={giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol}
                                                                className="w-6 h-6 mr-2"
                                                            />
                                                            <span>{giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol}</span>
                                                        </div>
                                                    ),
                                                }}
                                                onChange={handleGiveCurrencyChange} // Используем уже объявленную функцию
                                                suffixIcon={<FaAnglesDown />}
                                                popupMatchSelectWidth={false}
                                                style={{ width: '50%', height: '48px' }}
                                                className="py-1 ml-2 font-semibold text-left cursor-pointer sm:text-sm flex items-center"
                                            >
                                                {giveCurrencies.map((currency) => (
                                                    <Option key={currency.id} value={currency.id.toString()}>
                                                        <div className="flex items-center">
                                                            <img
                                                                src={currency.image_url}
                                                                alt={currency.symbol}
                                                                className="w-5 h-5 mr-2"
                                                            />
                                                            {currency.symbol}
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>

                                        {/* Минимальная и максимальная суммы */}
                                        <div className="text-xs mt-1 ml-1 font-semibold text-gray-500">
                                            {selectedGiveCurrencyObj ? (
                                                <>
                                                    Мин: <span className="text-purple-500">{parseFloat(selectedGiveCurrencyObj.min_exchange_amount).toFixed(6)}</span> - Макс: <span className="text-purple-500">{parseFloat(selectedGiveCurrencyObj.max_exchange_amount).toFixed(6)}</span>
                                                </>
                                            ) : (
                                                <span className="text-red-500">Валюта не выбрана</span>
                                            )}
                                        </div>
                                    </div>
                                    <Button className='mt-5 mb-3 hidden lg:block' color='primary' shape="circle" type='link' onClick={swapCurrencies}>
                                        <ArrowsRightLeftIcon className="size-6" />
                                    </Button>
                                    <div className='flex flex-col space-y-2 mt-6 lg:mt-0'>
                                        {/* Лейбл */}
                                        <label htmlFor="getCurrency" className="ml-1 flex justify-between font-semibold items-center text-md text-gray-800">
                                            <span className='flex items-center'> Я хочу получить <FiCornerRightDown className="ml-1 text-purple-500" /></span>
                                            {exchangeRate !== null && (
                                                <Chip variant="light" color="primary" className='bg-white rounded-md shadow-md'>
                                                    1 {giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.symbol || ''} / {exchangeRate} {getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol || ''}
                                                </Chip>
                                            )}
                                        </label>

                                        {/* Input с интегрированным Select */}
                                        <div className="flex items-center border rounded-lg  shadow-md p-2 h-[48px] bg-white w-full">
                                            <Input
                                                size='large'
                                                value={receivedAmount}
                                                readOnly
                                                placeholder="0.00"
                                                className="w-full border-none focus:ring-0 bg-white font-semibold"
                                            />

                                            <Select
                                                labelInValue
                                                size='large'
                                                variant='filled'
                                                value={{
                                                    value: selectedGetCurrency, // Значение текущей выбранной валюты
                                                    label: (
                                                        <div className="flex items-center justify-between">
                                                            <img
                                                                src={getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.image_url}
                                                                alt={getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol}
                                                                className="w-6 h-6 mr-2"
                                                            />
                                                            <span>{getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.symbol}</span>
                                                        </div>
                                                    ),
                                                }}
                                                onChange={handleGetCurrencyChange} // Используем уже объявленную функцию
                                                suffixIcon={<FaAnglesDown />}
                                                popupMatchSelectWidth={false}
                                                style={{ width: '50%', height: '48px' }}
                                                className="py-1 ml-2 font-semibold text-left cursor-pointer sm:text-sm flex items-center"
                                            >
                                                {getCurrencies.map((currency) => (
                                                    <Option key={currency.id} value={currency.id.toString()}>
                                                        <div className="flex items-center">
                                                            <img
                                                                src={currency.image_url}
                                                                alt={currency.symbol}
                                                                className="w-5 h-5 mr-2"
                                                            />
                                                            {currency.symbol}
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>

                                        {/* Резерв и Ввод адреса */}
                                        <div className="text-xs mt-1 ml-1 font-semibold text-gray-500">
                                            Резерв: <span className="text-purple-500">{getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.reserve || ''}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center items-center'>{errors.amount && <p className="text-red-500">{errors.amount}</p>}</div>
                                <Divider className='my-4' />
                                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1'>
                                    <div className="w-full">
                                        <Form.Item
                                            validateStatus={errors.walletAddress ? 'error' : ''}
                                            help={errors.walletAddress || ''}
                                            layout="vertical"
                                            className='font-semibold ml-1'
                                            label={`Адрес ${getCurrencies.find(currency => currency.id.toString() === selectedGetCurrency)?.name || ''}`}
                                        >
                                            <Input
                                                type="text"
                                                value={walletAddress}
                                                onChange={(e) => {
                                                    setWalletAddress(e.target.value);
                                                    validateWalletAddress(e.target.value);
                                                }}
                                                placeholder="Введите адрес кошелька"
                                                className="border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 shadow-md"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="w-full">
                                        <Form.Item
                                            validateStatus={errors.email ? 'error' : ''}
                                            help={errors.email || ''}
                                            layout="vertical"
                                            className='font-semibold ml-1'
                                            label={`Mail адрес`}
                                        >
                                            <Input
                                                type="text"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    validateEmail(e.target.value);
                                                }}
                                                placeholder="example@example.com"
                                                className="border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500 shadow-md"
                                            />
                                        </Form.Item>

                                    </div>
                                    <div className='my-0 lg:my-5 flex flex-col justify-start space-y-2'>
                                        <Button
                                            className="mt-2.5 bg-blue-500 border-2 border-blue-400 text-white shadow-md"
                                            size="large"
                                            color="primary"
                                            onClick={handleSubmit}
                                        // Удаляем disabled={!isFormValid || isLoading}
                                        >
                                            {isLoading ? (
                                                <Chip color="primary" startContent={<ArrowPathIcon className="size-5 mr-1 animate-spin" />}>Обработка...</Chip>
                                            ) : (
                                                'Приступить к обмену'
                                            )}
                                        </Button>
                                        {errors.submit && (
                                            <div className="text-red-500 text-center mt-2">
                                                {errors.submit}
                                            </div>
                                        )}
                                        <Checkbox
                                            required
                                            checked={isTermsAccepted}
                                            onChange={(e) => setIsTermsAccepted(e.target.checked)}
                                            className='text-xs'
                                        >
                                            Соглашаюсь с <Link className='text-xs' href='https://disco-exchange.gitbook.io/disco-exchange' color='primary' underline="always" showAnchorIcon isExternal>Правилами</Link>
                                        </Checkbox>

                                        <Checkbox
                                            required
                                            checked={isAMLAccepted}
                                            onChange={(e) => setIsAMLAccepted(e.target.checked)}
                                            className='text-xs'
                                        >
                                            Соглашаюсь с <Link className='text-xs' href='https://disco-exchange.gitbook.io/disco-exchange/aml-kyc-ctf' color='primary' underline="always" showAnchorIcon isExternal>AML/CTF & KYC</Link>
                                        </Checkbox>
                                    </div>

                                </div>
                                <Divider className='mt-4 lg:mt-0 mb-4' />
                                {error && (
                                    <div className="bg-red-500 text-white text-center p-2 rounded">
                                        {error}
                                    </div>
                                )}
                                <p className='font-semibold text-center lg:text-right text-xs mb-4'>
                                    Операции с картами производятся в полуавтоматическом режиме во время работы оператора и занимают до 60 минут после получения 20-го подтверждения сети.
                                </p>
                                <p className='font-semibold text-center lg:text-right text-xs'>
                                    Внимание! Каждая транзикация проходит AML-проверку. В случае если криптовалюта отправленная Вами будет иметь High Risk X или связана с нелегитимными источниками будет проводиться проверка личности.
                                </p>
                            </CardBody>
                        </Card>
                    </div>
                    <Card className='border-5 bg-transparent border-zinc-100 rounded-3xl mt-8'>
                        <CardBody className='flex'>
                            <p className='mb-2 flex justify-center items-center font-semibold'><FcExpired className='size-6 mr-2' />Обратите внимание</p>
                            <p className='text-xs'>
                                <span className='font-semibold'>
                                    Необходимое количество подтверждений в сети для зачисления средств на биржевой счет: BTC-2, ETH-32, ERC20-32, TRC20-20, BCH-10, LTC-10, XMR-10, DASH-10, ZEC-100, DOGE-15, ETC-300, NEO-10, ADA-40, TRX-20.
                                </span>
                                <br /><br />
                                Для обмена вам необходимо выполнить несколько шагов:

                                Заполните все поля представленной формы. Нажмите «Произвести оплату».
                                Ознакомьтесь с условиями договора об оказании услуг обмена, при его принятии, пожалуйста, поставьте галочку в соответствующем поле и нажмите кнопку «Создать заказ».
                                Оплатите заказ. Для этого переведите необходимую сумму, следуя инструкциям на нашем сайте.
                                После этого система перенаправит вас на страницу «Статус заказа», где будет отображаться статус вашего перевода.
                            </p>
                        </CardBody>
                    </Card>

                </div>

            </section>

            <section className="py-10 bg-white sm:py-8 lg:py-12">
                <div className="mx-auto max-w-7xl">
                    <div className="mx-auto text-center">
                        <h2 className="font-semibold text-center text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center"><FcVip /> Наши партнеры</h2>
                        <p className="text-center mt-4 text-lg font-normal text-zinc-500 sm:mt-8 flex justify-center items-center">Это специалисты мирового уровня, благодаря им мы выстроили безопасную платформу обменов!</p>
                    </div>

                    <div className="grid items-center max-w-4xl grid-cols-2 mx-auto mt-12 md:mt-20 md:grid-cols-4 gap-x-10 gap-y-16">
                        <div>
                            <img className="object-contain w-full h-6 mx-auto" src="/assets/img/partn/payeer.svg" alt="" />
                        </div>

                        <div>
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/pf.png" alt="" />
                        </div>

                        <div>
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/epay.svg" alt="" />
                        </div>

                        <div>
                            <img className="object-contain w-full mx-auto h-7" src="/assets/img/partn/exmo.svg" alt="" />
                        </div>

                        <div className="hidden md:block">
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/okx.svg" alt="" />
                        </div>

                        <div className="hidden md:block">
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/kr.svg" alt="" />
                        </div>

                        <div className="hidden md:block">
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/bt.svg" alt="" />
                        </div>

                        <div className="hidden md:block">
                            <img className="object-contain w-full h-8 mx-auto" src="/assets/img/partn/bb.svg" alt="" />
                        </div>
                    </div>

                    <div className="flex items-center justify-center mt-10 space-x-3 md:hidden">
                        <div className="w-2.5 h-2.5 rounded-full bg-blue-600 block"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-gray-300 block"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-gray-300 block"></div>
                    </div>

                    <p className="mt-10 text-base text-center text-gray-500 md:mt-20">и еще 7 партнеров...</p>
                </div>
            </section>

            <section className="py-10 blured sm:py-16 lg:py-24">
                <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="mx-auto text-center">
                        <h2 className="font-semibold text-center lg:text-start text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center"><FcPositiveDynamic className='mx-2' /> Наши преимущества</h2>
                        <p className="mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">Каждый счетчик привязан к независимым метрикам, что делает их прозрачными, для вашего обозрения!</p>
                    </div>

                    <div className="grid grid-cols-1 gap-8 mt-10 text-center lg:mt-24 sm:gap-x-8 md:grid-cols-3">
                        <div>
                            <h3 className="font-bold text-7xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 1+ </span>
                            </h3>
                            <p className="mt-4 text-xl font-medium text-gray-900">Лет работы</p>
                            <p className="text-base mt-0.5 text-gray-500">Создание успешного пункта обмена</p>
                        </div>

                        <div>
                            <h3 className="font-bold text-7xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 1542 </span>
                            </h3>
                            <p className="mt-4 text-xl font-medium text-gray-900">Заявок</p>
                            <p className="text-base mt-0.5 text-gray-500">Каждая заявка была тчательно обработана</p>
                        </div>

                        <div>
                            <h3 className="font-bold text-7xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-fuchsia-600 to-blue-600"> 10+ </span>
                            </h3>
                            <p className="mt-4 text-xl font-medium text-gray-900">Сотрудников</p>
                            <p className="text-base mt-0.5 text-gray-500">Успешно и кропотливо трудятся для вас</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-col items-center">
                        <div className="text-center">
                            <h2 className="font-semibold text-center lg:text-start text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center">
                                <FcFeedback className='mx-2' /> Фидбек клиентов
                            </h2>
                            <p className="mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                                Делитесь и смотрите отзывы с разных платформ, наших клиентов
                            </p>
                        </div>

                        <div className="relative mt-10 md:mt-24">
                            <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                                <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                                    style={{ background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)" }}>
                                </div>
                            </div>

                            <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                                {reviews.map((review) => (
                                    <div key={review.id} className="flex flex-col overflow-hidden">
                                        <div className="flex flex-col justify-between flex-1 p-6 rounded-3xl blured border-5 border-zinc-100 lg:py-8 lg:px-7">
                                            <div className="flex-1">
                                                <div className="flex items-center">
                                                    {[...Array(review.rating)].map((_, index) => (
                                                        <svg key={index} className="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                        </svg>
                                                    ))}
                                                </div>

                                                <blockquote className="flex-1 mt-8">
                                                    <p className="text-lg leading-relaxed text-gray-900 font-pj font-semibold">"{review.description}"</p>
                                                </blockquote>
                                            </div>

                                            <div className="flex items-center mt-8">
                                                <SiTrustpilot className='text-green-500 mx-2 object-cover rounded-full w-11 h-11' />
                                                <div className="ml-4">
                                                    <p className="text-base font-bold text-gray-900 font-pj">{review.name}</p>
                                                    <p className="mt-0.5 text-sm font-pj text-gray-600">Отзыв Клиента</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Link href='/reviews' className="flex justify-center items-center mt-8">
                                <HiOutlineExternalLink className='mx-2' /> Открыть больше отзывов
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <div className="blured">
                <div className="text-center pt-20">
                    <h2 className="font-semibold text-center lg:text-start text-3xl text-black sm:text-4xl lg:text-5xl xl:text-6xl flex justify-center items-center">
                        <FcAcceptDatabase className='mx-2' /> Резерв и История
                    </h2>
                    <p className="mt-3 text-xl leading-relaxed text-gray-600 md:mt-8">
                        Акутальная информация по резервам, а также последним обменам клиентов
                    </p>
                </div>
                <Tabs defaultActiveKey="latest" tabPosition="top" className='font-semibold mx-4 lg:mx-24 pb-20'>
                    <TabPane tab="Последние обмены" key="latest">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 py-4">
                            {fakeExchanges && fakeExchanges.length > 0 ? (
                                fakeExchanges.map((exchange, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-center p-3 bg-white border-5 border-zinc-100 rounded-2xl shadow-md"
                                    >
                                        <div className="flex items-center space-x-3">
                                            {/* From Currency */}
                                            <div className="flex items-center space-x-2">
                                                <img
                                                    src={exchange.from_currency.image_url}
                                                    alt={exchange.from_currency.symbol}
                                                    className="w-5 h-5"
                                                />
                                                <div className="text-sm font-semibold text-black">
                                                    {Number(exchange.amount_give).toFixed(3)}{" "}
                                                    {exchange.from_currency.symbol}
                                                </div>
                                            </div>

                                            <ArrowRightCircleIcon className="text-black size-6" />

                                            {/* To Currency */}
                                            <div className="flex items-center space-x-2">
                                                <div className="text-sm font-semibold text-black">
                                                    {Number(exchange.amount_receive).toFixed(2)}{" "}
                                                    {exchange.to_currency.symbol}
                                                </div>
                                                <img
                                                    src={exchange.to_currency.image_url}
                                                    alt={exchange.to_currency.symbol}
                                                    className="w-5 h-5"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-center text-gray-400">Нет фейковых обменов</p>
                            )}
                        </div>
                    </TabPane>

                    <TabPane tab="Резервы" key="reserves">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 py-4">
                            {giveCurrencies.map(currency => (
                                <div key={currency.id} className="px-4 flex items-center justify-between p-1 bg-white border-5 border-zinc-100 rounded-2xl shadow-md">
                                    <div className="flex justify-between items-center gap-4">
                                        <img src={currency.image_url} alt={currency.symbol} className="w-10 h-10" />
                                        <div>
                                            <p className="font-bold">{currency.name}</p>

                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <p className="font-semibold mr-2">{currency.reserve.toLocaleString()}</p> <p className="text-gray-500">{currency.symbol}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                </Tabs>


            </div>
        </>
    );
};

export default Welcome;
